const Missions = () => import('@/views/ziqni/missions');
const PreviewMission = () => import('@/views/ziqni/achievements/PreviewAchievement');

export default {
  path: 'missions',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Missions',
      component: Missions,
    },
    {
      path: 'detail/:id',
      name: 'PreviewMission',
      component: PreviewMission,
    },
  ],
}