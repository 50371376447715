const InstantWins = () => import('@/views/ziqni/instant-wins/InstantWinsTable');
const PreviewInstantWin = () => import('@/views/ziqni/instant-wins/InstantWinDetails');
const EditInstantWin = () => import('@/views/ziqni/instant-wins/UpdateInstantWin');
const CreateInstantWin = () => import('@/views/ziqni/instant-wins/CreateInstantWin');

export default {
  path: 'instant-wins',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'InstantWins',
      component: InstantWins,
    },
    {
      path: 'create',
      name: 'CreateInstantWin',
      component: CreateInstantWin,
    },
    {
      path: 'detail/:id',
      name: 'PreviewInstantWin',
      component: PreviewInstantWin,
    },
    {
      path: 'edit/:id',
      name: 'EditInstantWin',
      component: EditInstantWin,
    }
  ],
}